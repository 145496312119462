import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import auth from '../api/auth';

const routes = [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue')
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/clientes',
    name: 'clientes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Clientes.vue')
  },
  // {
  //   path: '/clientes/detalle/:id',
  //   name: 'clientesdetalle',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../components/ClientesDetalle.vue')
  // },
  {
    path: '/clientes/detalle/:id',
    component: () => import(/* webpackChunkName: "about" */ '../components/ClientesDetalle.vue'),
    beforeEnter: async (to, from, next) => {
      let idClienteUrl = parseInt(to.params.id); // Obtener el ID del cliente de la URL
      let idComercialVentas = auth.getComercialLogged(); // Obtener el ID del comercial logueado
      
      let respuestaContactos = await auth.getObtenerTodosLosContactosDelComercialSinPaginar(idComercialVentas);
      let clientes = respuestaContactos.data.ent_m;
      let clientePermitido = clientes.some(cliente => cliente.id === idClienteUrl);
      
      if (clientePermitido) {
        // Si el cliente es válido, permite el acceso a la ruta
        next();
      } else {
        // Si el cliente no es válido, redirecciona a una página de error o a la página principal
        alert('Acceso denegado: No tienes permiso para acceder a este cliente.');
        next('/clientes'); // Redirecciona a la página de error
      }
    }
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Pedidos.vue')
  },
  {
    path: '/presupuestos',
    name: 'presupuestos',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/Presupuestos.vue')
  },
  {
    path: '/presupuestos/modificarpresupuesto/:id',
    name: 'modificarpresupuesto',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ModificarPresupuesto.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router
