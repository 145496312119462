import axios from "axios";
import { sha3_256 } from 'js-sha3';
import Cookies from "js-cookie";
// import { resolve } from "core-js/fn/promise";

export default {

    //USUARIO - COOKIES
    setUserLogged(userLogged) {
        Cookies.set("userLogged", userLogged);
    },
    getUserLogged() {
        return Cookies.get("userLogged");
    },
    deleteUserLogged() {
        Cookies.remove('userLogged');
    },

    //ID COMERCIAL
    setComercial(comercialLogged){
        Cookies.set("idComercial", comercialLogged);
    },
    getComercialLogged(){
        return Cookies.get("idComercial");
    },
    deleteComercialLogged(){
        Cookies.remove("idComercial");
    },

    //ID COMERCIAL
    setNombreComercial(nombreComercialLogged){
        Cookies.set("nombreComercial", nombreComercialLogged);
    },
    getNombreComercialLogged(){
        return Cookies.get("nombreComercial");
    },
    deleteNombreComercialLogged(){
        Cookies.remove("nombreComercial");
    },
    
    // METODOS GET Y POST
    // ARTICULOS
    // OBTENER ARTIUCLOS
    async getObtenerArticulos() {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/art_m?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getObtenerTodosLosArticulos(pagina) {
        return axios
        //.get('https://vmod.proynet.es/decoman/core_dat_dat/v1/art_m?page%5Bnumber%5D='+pagina+'&api_key=CAXZbJEknwCR03Ox8pcU')
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/art_m?page%5Bnumber%5D='+pagina+'&filter%5Bid_on_act%5D=1&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    //OBTENER NOMBRE DEL ARTICULO
    async getObtenerNombre(idArticulo) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/art_m?filter%5Bid_on%5D='+idArticulo+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // OBTENER PRECIOS DE ESE ARTICULO
    async getObtenerPrecioDelArticulo(idArticulo) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_tar_art_g?filter%5Bid%5D='+idArticulo+'&api_key=CAXZbJEknwCR03Ox8pcU')
        // .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/art_m?filter%5Bid%5D='+idArticulo+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // OBTENER IMAGEN ARTICULO
    // async getImagenArticulo(idArticulo) {
    //     return axios
    //     .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/_process/dev_img?param%5Bart%5D='+idArticulo+'&api_key=CAXZbJEknwCR03Ox8pcU')
    // },

    // CONTACTOS
    // OBTENER CONTACTOS
    async getContactos() {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/ent_m?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getObtenerTodosLosContactos(pagina) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/ent_m?page%5Bnumber%5D='+pagina+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getObtenerTodosLosContactosDelComercial(pagina, idComercial) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/ent_m?page%5Bnumber%5D='+pagina+'&filter%5Bcmr%5D='+idComercial+'&api_key=CAXZbJEknwCR03Ox8pcU')
        // .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/ent_m?page%5Bnumber%5D='+pagina+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getObtenerTodosLosContactosDelComercialSinPaginar(idComercial) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/ent_m?filter%5Bcmr%5D='+idComercial+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // OBTENER CONTACTOS POR ID
    async getContactosPorId(idCliente) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/ent_m/'+idCliente+'?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // OBTENER MAILS TELEFONOS ETC
    async getTelefonosEmails(idCliente) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/ctt_m?filter%5Bent%5D='+idCliente+'&filter%5Bctt_clf%5D=T&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    


    // INFOMRES
    // OBTENER INFORMES
    async getInformes() {
        let informes = [
            { nombreInforme: 'Informe Uno', enlaceInforme: 'doc-1.pdf' }, { nombreInforme: 'Informe Dos', enlaceInforme: 'doc-2.pdf' },
            { nombreInforme: 'Informe Tres', enlaceInforme: 'doc-3.pdf' }, { nombreInforme: 'Informe Cuatro', enlaceInforme: 'doc-4.pdf' },
            { nombreInforme: 'Informe Cinco', enlaceInforme: 'doc-5.pdf' }, { nombreInforme: 'Informe Seis', enlaceInforme: 'doc-6.pdf' }
        ];
        return informes;
    },


    // PEDIDOS DE VENTA (LOS PEDIDOS)
    // OBTENER PEDIDOS DE VENTA (obtiene todos los pedidos)
    async getPedidosDeVenta() {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_g?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // OBTENER LINEAS DE PEDIDOS DE VENTA - vta_ped_lin_g - FILTRADO POR EL ID DEL PEDIDO
    async getPedidosVentaConIdDelPedido(idPedido) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_lin_g?filter%5Bvta_ped_pdt%5D='+idPedido+'&api_key=CAXZbJEknwCR03Ox8pcU')  
    },
    // OBTENER PEDIDOS DE VENTA DE X CLIENTE (11 es Decoman por ejemplo, nos sacaria los pedidos de Decoman)
    async getPedidosDeVentaPorIdDelCliente(idClienteV) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_g?filter%5Bclt%5D='+idClienteV+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getPedidosDeVentaDelComercial(idComercial) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_g?filter%5Bcmr%5D='+idComercial+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getPedidosDeVentaDelComercialPaginado(idComercial, pagina) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_g?page%5Bnumber%5D='+pagina+'&filter%5Bcmr%5D='+idComercial+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getUltimoPedidoDeUnClient(idCliente) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_g?sort=-fch,-id&filter%5Bclt%5D='+idCliente+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },

    // LINEAS DE PEDIDO DE VENTA (LOS ARTICULOS DEL PEDIDO, SON CADA UNO DE LOS ARTICULOS QUE AÑADIMOS AL PEDIDO)
    // OBTENER LINEAS DE PEDIDO DE VENTAS (obtiene todas las lineas de venta)
    async getLineasPedidosDeVenta() {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_lin_g?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // OBTENER LINEAS DE VENTA POR CLIENTE (obtiene las lineas de pedido de X cliente, va por ID de cliente)
    async getLineasPedidosDeVentaPorIdDelCliente(idCliente) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_lin_g?filter%5Bclt%5D='+idCliente+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // BORRAR LINEAS DE PEDIDO DE VENTA
    async postBorrarLineasPedidoEnNuevoPedido(idLinea) {
        axios.delete('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_lin_g/'+idLinea+'?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // AFEGIR LINEAS DE PEDIDO A UN CLIENTE (vta_ped_lin_g)
    async postAnyadirLineasPedidoEnNuevoPedido(idCliente, numLineas, idPedidoVenta, idArticuloNuevo, cantidadArticulo, imp) {
        //console.log("ID CLI: " + idCliente );
        axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_lin_g?api_key=CAXZbJEknwCR03Ox8pcU', {
            id: 0,
            vta_ped: idPedidoVenta,
            num_lin: numLineas,
            fch: "2023-01-17",
            fch_ent: "2023-01-17",
            clt: idCliente,
            ser: 0,
            art: idArticuloNuevo,
            can_ped: cantidadArticulo,
            can_srv: 0,
            can_pdt: 0,
            pre: 0,
            por_dto: 0,
            por_dto_2: 0,
            imp_pdt: 0,
            imp: imp,
            reg_iva_vta: "string",
            apl_ret_irp: true,
            srv: true,
            cnc: true,
            est: "string"
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.log(error);
        });
    },

    // IMAGENES
    async getImagenProducto(idArticulo) {
        return axios
        .get('https://www.decomansl.es/scripts/miniatura-producto.php?product='+idArticulo+'')
    },    

    // FACTURAS
    async getFacturas(idCliente) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_fac_g?filter%5Bclt%5D='+idCliente+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async enviarFacturas(idFactura) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/_process/env_fac_eml?param%5Bvta_fac%5D='+idFactura+'8&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // DETALLES FACTURA
    async getDatosVerFactura(idFactura) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_fac_g?filter%5Bid%5D='+idFactura+'&api_key=CAXZbJEknwCR03Ox8pcU');
        //http://lab
    },
    async getLineasVerFactura(idFactura) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/mov_g?filter%5Bvta_fac%5D='+idFactura+'&api_key=CAXZbJEknwCR03Ox8pcU');
        //http://lab
    },

    //PRESUPUESTOS
    async crearNuevoPresupuesto(idCliente, idDireccionEnvio) {
        //Ponemos el return porque sino el return del then no devolveria nada
        return axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_g?api_key=CAXZbJEknwCR03Ox8pcU', {
            "clt": idCliente,
            "emp_div": "1",
            "dir_env": idDireccionEnvio, //Le pasamos la direccion de envio del pedido al crear un nuevo presupuesto,
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            ).then(response => {
                console.log(response.data);
                return response.data;
            }).catch(error => {
                console.log(error);
        });
    },
    async borrarPresupuesto(idPresupuesto) {
        axios.delete('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_g/'+idPresupuesto+'?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getPresupuestos() {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_g?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getPresupuestosPendientes(idComercial) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_g?filter%5Bcmr%5D='+idComercial+'&filter%5Best%5D=P&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getPresupuestosEseCliente(idCliente) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_g?filter%5Bclt%5D='+idCliente+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getPresupuestoConId(idPresupuesto) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_g/'+idPresupuesto+'?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getLineasPresupuesto(idPresupuesto) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_lin_g?filter%5Bvta_pre%5D='+idPresupuesto+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async getLineaPresupuestoConcreta(idLinea) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_lin_g/'+idLinea+'?api_key=CAXZbJEknwCR03Ox8pcU');
    },
    async postModificarPresupuesto(idPresupuesto, observaciones) {
        //ESTO LO TENIA COMO EL METODO DE ARRIBA PERO NECESITAVA UN
        //RESOLVE PARA PODER USAR EL ID QUE CREA
        return new Promise((resolve) => {
            resolve (
                axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_g/'+idPresupuesto+'?api_key=CAXZbJEknwCR03Ox8pcU', {
                    obs: observaciones,
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            )
            )
        })
    },
    // CAMBIAR CANTIDAD LINEA PRESUPUESTO
    async postCambiarLineasPresupuestoCantidad(idLineaPresupuesto, vta_pre,
        num_lin, clt, art, cantidad, pre, importe) {
        //console.log("ID CLI: " + idCliente );
        axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_lin_g/'+idLineaPresupuesto+'?api_key=CAXZbJEknwCR03Ox8pcU', {
            vta_pre: vta_pre,
            num_lin: num_lin,
            clt: clt,
            art: art,
            can: cantidad,
            pre: pre,
            imp: importe,
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            ).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.log(error);
        });
    },    
    // CAMBIAR PORCENTAJE LINEA PRESUPUESTO
    async postCambiarLineasPresupuestoPorcentaje(idLineaPresupuesto, porcentaje) {
        axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_lin_g/'+idLineaPresupuesto+'?api_key=CAXZbJEknwCR03Ox8pcU', {
            por_dto: porcentaje,
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            ).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.log(error);
        });
    },  
    // AFEGIR LINEAS DE PRESUPUESTO A UN PRESUPUESTO (vta_ped_lin_g)
    async postAnyadirLineasPedidoEnNuevoPresupuesto(idCliente, idPresupuestoVenta, idArticuloNuevo, cantidadArticulo, pre, porcentajeDescuento) {
        //console.log("ID CLI: " + idCliente );
        const response = await axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_lin_g?api_key=CAXZbJEknwCR03Ox8pcU', {
            vta_pre: idPresupuestoVenta,
            fch: "2024-02-14",
            fch_ent: "2024-02-14",
            clt: idCliente,
            art: idArticuloNuevo,
            can: cantidadArticulo,
            pre: pre,
            por_dto: porcentajeDescuento,
            por_dto_2: 0,
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    },    
    // BORRAR PRESUPUESTO
    borrarLineaPresupuesto(idPresupuesto) {
        axios.delete('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_lin_g/'+idPresupuesto+'?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // CREA UN PEDIDO DE UN PRESUPUESTO (cuan aceptem la cistella del pressupost)
    crearPedidoPresupuesto(idPresupuesto) {
        //ESTO LO TENIA COMO EL METODO DE ARRIBA PERO NECESITAVA UN
        //RESOLVE PARA PODER USAR EL ID QUE CREA
        return new Promise((resolve) => {
            resolve (
                axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/_process/vta_pre_g_to_vta_ped_g_web?param%5Bvta_pre_id%5D='+idPresupuesto+'&api_key=CAXZbJEknwCR03Ox8pcU', {
                    tp_vta_ped: 'R' // Saber a traves de donde se ha efectuado el pedido (Web, App Comerciales..)
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            )
            )
        })
    }, 
    // MODIFICAR LAS LINEAS DE PEDIDO SOBRE EL PEDIDO DE PRESUPUESTO QUE HEMOS CREADO ANTERIORMENTE
    async postModificarLineasTotalesPresupuesto(idPresupuesto, numeroLineas) {
        //ESTO LO TENIA COMO EL METODO DE ARRIBA PERO NECESITAVA UN
        //RESOLVE PARA PODER USAR EL ID QUE CREA
        return new Promise((resolve) => {
            resolve (
                axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_g/'+idPresupuesto+'?api_key=CAXZbJEknwCR03Ox8pcU', {
                    num_lin_ace: numeroLineas,
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            ))
        })
    },
    // MODIFICAR PARA ESTABLECER EL REPRESENTANTE POR DEFECTO
    async postModificarPedidoPresupuesto(idPresupuesto) {
        //ESTO LO TENIA COMO EL METODO DE ARRIBA PERO NECESITAVA UN
        //RESOLVE PARA PODER USAR EL ID QUE CREA
        return new Promise((resolve) => {
            resolve (
                axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_g/'+idPresupuesto+'?api_key=CAXZbJEknwCR03Ox8pcU', {
                    tp_vta_ped: 'R'
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            ))
        })
    },


    // CREAR PEDIDO (VALIDAR PEDIDO) - PEDIDOS DE VENTA SON CADA UNO DE LOS PEDIDOS
    // AFEGIR PEDIDO DE VENTA (Dar un pedido de alta)
    async postValidarPedidoVenta(idCliente, fecha, precioBase, precioSoloIVA, precioTotal, idComercialVentas, direccionPrincipalId, observaciones, referenciaPedido) {
        //ESTO LO TENIA COMO EL METODO DE ARRIBA PERO NECESITAVA UN
        //RESOLVE PARA PODER USAR EL ID QUE CREA
        return new Promise((resolve) => {
            resolve (
                axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_g?api_key=CAXZbJEknwCR03Ox8pcU', {
                id: 0,
                emp_div: '1', // Tiene que estar a 1
                fch: fecha,
                fch_ent: fecha,
                ser: 0,
                num_doc: 0,
                num_ped: 0,
                su_ref: referenciaPedido, //Referencia del pedido generada mediante codigo
                clt: idCliente, //ID Cliente
                dir_env: direccionPrincipalId, //Direccion Principal Cliente (es un ID)
                fpg: 0,
                cmr: idComercialVentas, //ID Comercial
                alm: '1', // Almacen (Per defecte va informat a 1 també)
                obs: observaciones, //Observaciones
                num_lin_tot: 0,
                num_lin_srv: 0,
                est: "PROCESANDO",
                bas_gen: 0,
                bas_red: 0,
                bas_sup: 0,
                bas_esp: 0,
                bas_exe: 0,
                bas_tot: precioBase,
                pot: 0, // Este camp va a 0 pero es obligatori
                por_dto: 0, // Este camp va a 0 pero es obligatori
                bas_gen_dto: 0,
                bas_red_dto: 0,
                bas_sup_dto: 0,
                bas_esp_dto: 0,
                bas_exe_dto: 0,
                bas_tot_dto: 0,
                imp_dto: 0,
                por_iva_gen: 0,
                por_iva_red: 0,
                por_iva_sup: 0,
                por_iva_esp: 0,
                iva_gen: 0,
                iva_red: 0,
                iva_sup: 0,
                iva_esp: 0,
                iva_tot: precioSoloIVA,
                apl_req: true,
                por_req_gen: 0,
                por_req_red: 0,
                por_req_sup: 0,
                por_req_esp: 0,
                req_gen: 0,
                req_red: 0,
                req_sup: 0,
                req_esp: 0,
                req_tot: 0,
                apl_ret_irp: true,
                bas_ret_irp: 0,
                por_ret_irp: 0,
                ret_irp: 0,
                tot_ped: precioTotal,
                imp_pdt: 0,
                tp_vta_ped: 'R' // Saber a traves de donde se ha efectuado el pedido (Web, App Comerciales..)
            }, {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
            // ).then(response => {
            //     console.log("Respuesta de la validacion");
            //     console.log(response.data);
            // }).catch(error => {
            //     console.log(error);
            )
            )
        })

    },
    // BORRAR UN PEDIDO DE VENTA - PEDIDOS DE VENTA SON CADA UNO DE LOS PEDIDOS
    borrarPedidoVenta(idPedido) {
        axios.delete('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_g/'+idPedido+'?api_key=CAXZbJEknwCR03Ox8pcU')
    },
    // LISTAR LINEAS DE PEDIDO ASIGNADAS a un PEDIDO DE VENTA
    async obtenerLineasPedidoAsignadasAUnPedidoDeVenta(idCliente, vtaPed){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_ped_lin_g?filter%5Bclt%5D='+idCliente+'&filter%5Bvta_ped%5D='+vtaPed+'&api_key=CAXZbJEknwCR03Ox8pcU');
    },

    // COMERCIAL ESTADISTICAS
    async obtenerDatosVentasComercial(idComercial, ano){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/est_cmr_g?filter%5Bcmr_eje_emp%5D='+idComercial+'%2C'+ano+'&api_key=CAXZbJEknwCR03Ox8pcU');
    },

    // CLIENTE ESTADISTICAS
    async obtenerDatosVentasCliente(idCliente, ano){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/est_clt_g?filter%5Bclt_eje_emp%5D='+idCliente+'%2C'+ano+'&api_key=CAXZbJEknwCR03Ox8pcU');
    },

    // OBTENER DIRECCIONES
    async obtenerDireccionesEnvio(idCliente){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/dir_m?filter%5Bent%5D='+idCliente+'&api_key=CAXZbJEknwCR03Ox8pcU');
        // .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/dir_m?filter%5Bent_env%5D='+idCliente+'&api_key=CAXZbJEknwCR03Ox8pcU');
    },
    // OBTENER DIRECCION ENVIO 
    async obtenerDatosFacturacion(idDireccion){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/dir_m?filter%5Bid%5D='+idDireccion+'&api_key=CAXZbJEknwCR03Ox8pcU');
    },
    // OBTENER PAIS
    async obtenerPais(idPais) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/pai_m?filter%5Bid%5D='+idPais+'&api_key=CAXZbJEknwCR03Ox8pcU');
        //http://lab
    },

    // FORMAS DE PAGO
    async obtenerFormaDePago(idPago){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/fpg_m/'+idPago+'?api_key=CAXZbJEknwCR03Ox8pcU');
    },


    // PORCENTAJES Y DESCUENTOS
    async obtenerTarifaProducto(idArticulo){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_tar_art_g?filter%5Bart%5D='+idArticulo+'&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    async obtenerPorcentajeDescuento(idCliente, idArticulo){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_tar_exc?filter%5Bclt%5D='+idCliente+'&filter%5Bart%5D='+idArticulo+'&filter%5Btip%5D=p&api_key=CAXZbJEknwCR03Ox8pcU');
    },
    async obtenerPorcentajeDescuentoFamilia(idArticulo){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_tar_exc?filter%5Btar%5D=4&filter%5Bart%5D='+idArticulo+'&filter%5Btip%5D=p&api_key=CAXZbJEknwCR03Ox8pcU');
    },
    async obtenerPromocionesDeDescuentoPorPrecio(idArticulo){
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_tar_exc?filter%5Btar%5D=4&filter%5Bart%5D='+idArticulo+'&filter%5Btip%5D=a&api_key=CAXZbJEknwCR03Ox8pcU');
    },    
    async obtenerPrecioYDescuento(idArticulo, cantidad, idCliente) {
        return axios
        .get('https://vmod.proynet.es/decoman/core_dat_dat/v1/_process/dev_pre?param%5Bart%5D='+idArticulo+'&param%5Bcan%5D='+cantidad+'&param%5Bclt%5D='+idCliente+'&param%5Bdto%5D=0&param%5Bdto_man%5D=0&api_key=CAXZbJEknwCR03Ox8pcU')
    },
    
    //METODOS LOGIN, REGISTRO, ETC
    async metodoLogin(email, contra) {
        const user = { email, contra };
        var loginCambiado;
        var passCodificada;

        if (email.includes("@")) {
            loginCambiado = user.email.replace("@", "%40"); //El correo el @ lo pasamos a %40
            passCodificada = sha3_256(user.contra); //Codificamos la contrasenya
            
        } else {
            loginCambiado = user.email;
            passCodificada = sha3_256(user.contra); //Codificamos la contrasenya
        }

        return axios
        .get('https://vmod.proynet.es/decoman/login?usr=' + loginCambiado + '&pass=' + passCodificada)
    }
};


//Si no utilizamos lo de que no se guarde la linea de presupuesto si no guardamos 
// usamos esta
// AFEGIR LINEAS DE PRESUPUESTO A UN PRESUPUESTO (vta_ped_lin_g)
// async postAnyadirLineasPedidoEnNuevoPresupuesto(idCliente, idPresupuestoVenta, idArticuloNuevo, cantidadArticulo, pre, porcentajeDescuento) {
//     //console.log("ID CLI: " + idCliente );
//     axios.post('https://vmod.proynet.es/decoman/core_dat_dat/v1/vta_pre_lin_g?api_key=CAXZbJEknwCR03Ox8pcU', {
//         vta_pre: idPresupuestoVenta,
//         fch: "2024-02-14",
//         fch_ent: "2024-02-14",
//         clt: idCliente,
//         art: idArticuloNuevo,
//         can: cantidadArticulo,
//         pre: pre,
//         por_dto: porcentajeDescuento,
//         por_dto_2: 0,
//         }, {
//             headers: {
//                 'accept': 'application/json',
//                 'Content-Type': 'application/json'
//             }
//         }
//         ).then(response => {
//             console.log(response.data);
//         }).catch(error => {
//             console.log(error);
//     });
// },  