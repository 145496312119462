import { defineStore } from 'pinia'

export const useMyStore = defineStore({
  id: 'store',
  state: () => ({
    count: 1,
    name: 'Alan Dev',
    articulos: [], //En app vamos rellenando el array.
    clientes: [], //En app vamos rellenando el array.
  }),
//   actions: {
//     increment() {
//       this.count++
//     },
//     addItem(item) {
//       this.myArray.push(item)
//     }
//   }
})
