<template>
  <!-- LINK ICONS BOOTSTRAP -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">

  <!-- NAVBAR -->
  <div class="text-center p-1 bg-verde text-white">
    <i class="fa-thin fa-hand-wave"></i> Bienvenido de nuevo {{this.nombreComercial}} !
  </div>

  <!-- NAV 2024 -->
  <nav class="navbar navbar-expand-lg navbar-light px-3 py-2 bg-verde-decoman text-white">
    <div class="container">
      <a href="/" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
          <img src="@/assets/decoman.png" alt="">
        </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/">
              <a href="#" class="nav-link text-white">
                <img src="@/assets/dashboardwhite.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Dashboard
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/clientes">
              <a href="#" class="nav-link text-white">
                <img src="@/assets/users.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Listado de clientes
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/pedidos">
              <a href="#" class="nav-link text-white">
                <img src="@/assets/delivery.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Localizador de pedidos
              </a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/presupuestos">
              <a href="#" class="nav-link text-white">
                <img src="@/assets/budget.svg" alt="Dashboard" style="width: 26px;">
                <br>
                Presupuestos
              </a>
            </router-link>
          </li>          
          <li class="nav-item">
            <router-link to="/login" @click="logout">
              <a href="#" class="nav-link text-white"  v-if="userLogged">
                <img src="@/assets/log-out.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Cerrar Sesión
              </a>
            </router-link> 
            <a href="#" class="nav-link text-white" v-if="!userLogged">
              <img src="@/assets/log-in.svg" alt="Dashboard" style="width: 25px;">
              <br>
              Acceso
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- NAV 2024 -->

  <!-- Container principal -->
  <div class="row col-12"> <!--  vh-md-75 vh-xl-100 -->
    <!-- Loading -->
    <loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"/>

    <!-- Container Izquierda -->
    <div class="col-12 col-md-12 col-xl-4 bg-white p-4 p-lg-5 border">
      
      <!-- <h2>{{ storeCounter.name }}</h2> -->

      <!-- Tabla de clientes -->
      <h4 class="text-start pb-2">Últimos clientes</h4>
      <div class="text-start p-2 border rounded tablaScrollClientes">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Nombre cliente</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cliente in clientes" :key="cliente.nom_fis">
              <td> {{ cliente.id }} </td>
              <td> <router-link :to="'/clientes/detalle/'+cliente.id" class="text-primary"> {{ cliente.nom_fis }}</router-link> </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Tabla de los informes -->
      <h4 class="text-start mt-5 pb-2">Listado Informes</h4>
      <div class="col-12 text-start p-2 border rounded tablaScrollInformes">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nombre Informe</th>
              <th scope="col">Descargar Informe</th>
            </tr>
          </thead>
          <tbody>
            <!-- Iteramos sobre los informes y mostramos el nombre y la ruta de descarga -->
            <tr v-for="informe in informes" :key="informe.nombreInforme">
              <td> {{ informe.nombreInforme }}</td>
              <td> <a class="text-primary" :href="`/docs/doc-1.pdf`" download>
              <button type="button" class="btn btn-primary">Descargar <i class="bi bi-file-earmark-arrow-down"></i></button>
              </a></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <!-- Container Derecha -->
    <div class="col-12 col-md-12 col-xl-8 bg-white border p-4 p-lg-5">
      <!-- Titulo -->
      <h4 class="text-start pb-2">Grafico Ventas</h4>
      <!-- <div class="text-center p-2">
        Total pedidos de venta en {{this.anioPreAnterior}}: {{ this.numPedidosPreAnoAnterior }} € &nbsp;&nbsp;
        Total pedidos de venta en {{this.anioAnterior}}: {{ this.numPedidosAnoAnterior }} € &nbsp;&nbsp;
        Total pedidos de venta en {{this.anioActual}}: {{ this.numPedidosAnoActual }} € &nbsp;&nbsp;
      </div> -->
      <!-- Tabla de prueba uno -->
      <div class="text-start p-2 border rounded tablaScrollArticulos">
          <canvas id="myChart" style="height: 100%;width: 100%;"></canvas>
      </div>

      <div class="row text-start p-2 d-flex texto-totales">
        <div class="d-flex col-12 col-md-3">
          <div class="cuadro1" id="miCuadro"></div> &nbsp; Total ventas {{this.anioPreAnterior}}: {{ this.numPedidosPreAnoAnterior }} €
        </div>
        <div class="d-flex col-12 col-md-3">
          <div class="cuadro2" id="miCuadro"></div> &nbsp; Total ventas {{this.anioAnterior}}: {{ this.numPedidosAnoAnterior }} € 
        </div>
        <div class="d-flex col-12 col-md-3">
          <div class="cuadro3" id="miCuadro"></div> &nbsp; Total ventas {{this.anioActual}}: {{ this.numPedidosAnoActual }} €
        </div>
      </div>

    </div> <!-- Fin Container Derecha -->

  </div>
</template>

<script>
import axios from "axios";
import auth from '../api/auth';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Chart from 'chart.js/auto';
import { useMyStore } from '@/api/myStore'

export default {
  name: "Home",
  props: {
    msg: String,
  },
  computed: {
    userLogged() {
      return auth.getUserLogged();
    }
  },
  data() {
    return {
      isLoading: false, //Loading
      fullPage: true, //Loading
      clientes: null,
      informes: null,
      info: null,
      articulos: null,
      articulosGlobal: [],
      storeCounter: useMyStore(),
      nombreComercial: "",
      numPedidosAnoActual: 0,
      numPedidosAnoAnterior: 0,
      numPedidosPreAnoAnterior: 0,
      anioActual: 0,
      anioAnterior: 0,
      anioPreAnterior: 0
    }
  },
  components: {
      Loading
  },
  methods: {
    async downloadFile() {
      try {
        const response = await axios.get('/doc/doc-1.pdf', {
          responseType: 'blob',
        })
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'documento.pdf')
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.error(error)
      }
    },
    compararFechas(a, b) {
      // Si a es Invalid Date, lo tratamos como la fecha más temprana
      if (isNaN(a.getTime())) {
        return -1;
      }
      // Si b es Invalid Date, lo tratamos como la fecha más temprana
      if (isNaN(b.getTime())) {
        return 1;
      }
      // Comparamos las fechas normalmente
      return a - b;
    }
  },
  async mounted () {
    // MOUNTED ES LO QUE SE EJECUTA AL ENTRAR A UN COMPONENTE / PAGINA
    
    // COMPROBAR SI ESTA LOGUEADO, SI LO ESTA SE Q
    // SI NO SE QUEDA EN LA PANTALLA DE LOGIN
    if (auth.getUserLogged && auth.getUserLogged() != undefined) {
      //console.log("Usuario actualmente logeado");  
    } else {
      this.$router.push("/login");
    }
    
    // TRY CATCH DEL AUTH.JS PARA LAS FUNCIONES
    try {
      //NOMBRE COMERCIAL
      this.nombreComercial = auth.getNombreComercialLogged();

      this.isLoading = true; //Loading

      // OBTENEMOS LOS CONTACTOS
      // OBTENEMOS ID DEL COMERCIAL QUE LO TENEMOS GUARDADO EN UNA COOKIE
      var idComercialVentas = auth.getComercialLogged(); //ID del Comercial guardado en una Cookie
      var respuestaContactos = await auth.getObtenerTodosLosContactosDelComercialSinPaginar(idComercialVentas);


      // console.log("LOG RESPUESTA CONTACTOS ");
      // console.log(respuestaContactos.data.ent_m);
      this.clientes = respuestaContactos.data.ent_m;

      // console.log(this.clientes);

      // Ordenar el array this.clientes por el campo alt_fch
      this.clientes.sort((a, b) => this.compararFechas(new Date(b.alt_fch), new Date(a.alt_fch)));

      // Suponiendo que clientes es un array de números o cadenas
      // this.clientes.sort((a, b) => a - b);

      // Clientes de más nuevo a mas viejo
      this.clientes.reverse();
      
      // Me quedo con los ultimos 10 clientes mismamente
      this.clientes = this.clientes.slice(-10);

      // Clientes de más nuevo a mas viejo, hay que volverlo a hacer
      this.clientes.reverse();

      // OBTENEMOS LOS INFORMES
      // var respuestaInformes = await auth.getInformes();
      // // console.log("LOG RESPUESTA INFORMES ");
      // // console.log(respuestaInformes);
      // this.informes = respuestaInformes;

      this.isLoading = false //Loading

    } catch (error) {
      console.log(error);
    }

    // Esto va asociado a la grafica y lo llamo antes del datasets que hay justo debajo porque
    // sino no se actualiza el label ya que ocurre despues de renderizarlo
    // Obtenemos el año anterior para mostrar los datos del año pasado y si al hacer el if no 
    // es del año anterior es que es del nuevo
    this.anioActual = new Date().getFullYear();
    this.anioAnterior = this.anioActual - 1;
    this.anioPreAnterior = this.anioActual - 2;

    //MOSTRAR LA GRAFICA - - - - - - - - - - - - - -
    const ctx = document.getElementById('myChart');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',  'Noviembre', 'Diciembre'],
        datasets: [
          {
            label: 'Ventas ' + this.anioPreAnterior,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: [
              'rgba(144, 238, 144, 0.2)',
            ],
            borderColor: [
              'rgb(144, 238, 144)',
            ],
            borderWidth: 1
          },
          {
            label: 'Ventas ' + this.anioAnterior,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: [
              'rgba(173, 255, 47, 0.2)',
            ],
            borderColor: [
              'rgb(173, 255, 47)',
            ],
            borderWidth: 1
          },
          {
            label: 'Ventas ' + this.anioActual,
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            backgroundColor: [
              'rgba(102, 255, 80, 0.2)',
            ],
            borderColor: [
              'rgb(102, 255, 80)',
            ],
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });

    // RECORREMOS LOS PEDIDOS Y SI ENCUENTRA UN PEDIDO DE ESE MES LO AÑADIMOS A LA GRAFICA
    // console.log("CHART");
    // Obtenemos los pedidos hechos del cliente
    for (let index = 0; index < 3; index++) {

      let ano = (parseInt(new Date().getFullYear())-index);
      var datosVentaComercial = await auth.obtenerDatosVentasComercial(idComercialVentas, ano);
      // let datosVenta = datosVentaComercial.data.est_cmr_g[0];
      // console.log(datosVenta.id);

      if (datosVentaComercial && datosVentaComercial.data.est_cmr_g && datosVentaComercial.data.est_cmr_g.length > 0) {
        let datosVenta = datosVentaComercial.data.est_cmr_g[0];
        // console.log(datosVenta.imp_tot);

        for (let meses = 1; meses <= 12; meses++) {
          // myChart.data.datasets[index].data[meses] = datosVenta.imp_01; //La barra de pedidos
          let propertyName = "imp_" + (meses < 10 ? "0" + meses : meses); // Construimos el nombre de la propiedad
            if (index === 0) { // Si es el año actual 2024, accedemos al [2] porque es el de la derecha
              myChart.data.datasets[2].data[meses-1] = datosVenta[propertyName]; // Asignamos el valor correspondiente
            } else if (index === 1) { // Si es el año anterior 2023, accedemos al [1] porque es el del medio
              myChart.data.datasets[1].data[meses-1] = datosVenta[propertyName]; // Asignamos el valor correspondiente
            } else { // Si es el pre anterior 2022, accedemos al [0] porque es el de la izquierda
              myChart.data.datasets[0].data[meses-1] = datosVenta[propertyName]; // Asignamos el valor correspondiente
            }
          // myChart.data.datasets[index].data[meses-1] = datosVenta[propertyName]; // Asignamos el valor correspondiente
        }

        // console.log("Index " + index);
        // Poner el total de ventas
        if (index === 0) { // Si es el año actual 2024
          // console.log("AÑO 0 es " + this.anioActual);
          this.numPedidosAnoActual = datosVenta.imp_tot;
        } else if (index === 1) { // Si es el año anterior 2023
          // console.log("AÑO 1 es " + this.anioAnterior);
          this.numPedidosAnoAnterior = datosVenta.imp_tot;
        } else { // Si es el pre anterior 2022
          // console.log("AÑO RESTO es " + this.anioPreAnterior);
          this.numPedidosPreAnoAnterior = datosVenta.imp_tot; // ¿Debería ser this.numPedidosPreAnterior?
        }

      } else {
        // console.log("No se encontraron datos de venta para el año especificado.");
      }
    
    }

    // Llamamos a la grafica
    myChart;
    myChart.update(); //Notificar que los datos han cambiado
    // FIN MOSTRAR LA GRAFICA - - -

  }, // FIN DEL MOUNTED
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Nav */
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}

/* Tablas */
.tablaScrollClientes {
  overflow-y: scroll;
  height: 25vh;
}

.tablaScrollInformes {
  overflow-y: scroll;
   height: 25vh;
}

.tablaScrollArticulos {
  overflow-y: scroll;
  height: 65vh;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  /* Tablas */
  .tablaScrollClientes {
    overflow-y: scroll;
    height: 45vh;
  }

  .tablaScrollInformes {
    overflow-y: scroll;
    height: 35vh;
  }

  .tablaScrollArticulos {
    overflow-y: scroll;
    height: 45vh;
  }
}

/* Cuadros ventas  */
.texto-totales {
  font-size: 15px;
}

.cuadro1 {
  width: 15px;
  height: 15px;
  border: 2px solid rgb(144, 238, 144);
  background-color: rgba(144, 238, 144, 0.2); /* Color inicial */
  margin: 3px 0px 0px 0px;
}

.cuadro2 {
  width: 15px;
  height: 15px;
  border: 2px solid rgb(173, 255, 47);
  background-color: rgba(173, 255, 47, 0.2); /* Color inicial */
  margin: 3px 0px 0px 0px;
}

.cuadro3 {
  width: 15px;
  height: 15px;
  border: 2px solid rgb(102, 255, 80);
  background-color: rgba(102, 255, 80, 0.2); /* Color inicial */
  margin: 3px 0px 0px 0px;
}

/* Iconos */
::before {
    padding-bottom: 2%;
}

/* Quitar el row que inventa bootstrap */
.row {
  --bs-gutter-x: 0rem;
}
</style>
